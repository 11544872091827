import leftChevron from "./../../../images/leftChevron.svg"
import rightChevron from "./../../../images/rightChevron.svg"
import styled from "styled-components"
import { SampleNextArrow } from "./Slider.view"
import Slider from "react-slick"
import { ImageWithLinkOrNot } from "../ImageWithLinkOrNot"

type CardProps = {
  size: number
}
export const StyledSlider = styled(Slider)`
  overflow: hidden;
  padding: 0 50px 0 50px;
`

export const Card = styled.div<CardProps>`
  height: ${({ size }) => size}px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 1em;
  .slick-slide slick-cloned {
    width: ${({ size }) => size}px;
  }
`

export const ImageSlider = styled(ImageWithLinkOrNot)`
  width: 100%;
  object-fit: ${({ objectFit }) => objectFit};
  height: 100%;
`

export const Text = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.info.fontSize};
  font-weight: bold;
  margin-top: 5px;
`

export const SampleArrowStyled = styled(SampleNextArrow)`
  .slick-slider &.slick-arrow:before {
    content: " ";
  }
  .slick-slider &.slick-arrow:hover,
  .slick-slider &.slick-arrow:focus {
    transform: scale(2);
  }
  .slick-slider &.slick-arrow {
    transition: all 0.2s;
    transform: translate(0, -50px);
    z-index: 2;
  }
  element.style {
    display: block;
  }
`

export const SampleNextArrowStyled = styled(SampleArrowStyled)`
  &.slick-arrow {
    background-image: url(${rightChevron});
  }

  .slick-slider &.slick-arrow.slick-next:hover,
  .slick-slider &.slick-arrow.slick-next:focus {
    background-image: url(${rightChevron});
  }
  &.slick-next {
    right: 3%;
    width: 40px;
    height: 40px;
  }
`

export const SamplePrevArrowStyled = styled(SampleArrowStyled)`
  &.slick-arrow {
    background-image: url(${leftChevron});
  }
  .slick-slider &.slick-arrow.slick-prev:hover,
  .slick-slider &.slick-arrow.slick-prev:focus {
    background-image: url(${leftChevron});
  }
  &.slick-prev {
    left: 2%;
    width: 40px;
    height: 40px;
  }
`
