import React from "react"
import {
  Card,
  Text,
  SamplePrevArrowStyled,
  SampleNextArrowStyled,
  ImageSlider,
} from "./Slider.style"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { enumFrom } from "./../ImageWithLinkOrNot"
import { v4 as uuidv4 } from "uuid"
import { buildHtmlFromString } from "../../../lib/buildHtmlFromString"
const edjsHTML = require("editorjs-html")

interface Props {
  data: Record<string, any>
  numberOfCards?: number
  size?: number
  from?: enumFrom
  objectFit?: string
  arrows?: boolean
  dots?: boolean
  className?: string
}

export function SliderView({
  data,
  arrows,
  numberOfCards,
  size = 250,
  from = enumFrom.STRAPI,
  objectFit = "contain",
  dots,
  className,
}: Props) {
  var slidesToShow = data.length
  if (numberOfCards) {
    if (numberOfCards < slidesToShow) {
      slidesToShow = numberOfCards
    }
  }

  const settings = {
    dots: dots,
    autoplay: false,
    infinite: true,
    arrows: !!arrows,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    speed: 2000,
    nextArrow: <SampleNextArrowStyled />,
    prevArrow: <SamplePrevArrowStyled />,
  }
  return (
    <Slider {...settings} className={className}>
      {data &&
        data.map((item: any) => (
          <div key={uuidv4()}>
            <Card size={size}>
              <ImageSlider data={item} from={from} objectFit={objectFit} />
              <ImageUnder data={item} />
            </Card>
          </div>
        ))}
    </Slider>
  )
}

const ImageUnder = ({ data }) => {
  const item = {
    Image: {
      name: data.Image?.name,
      url: data.Image?.backgroundImage?.url,
      alt: data.Image?.alt,
    },
    Text: data?.Text,
  }
  let htmlFormatted = ""
  if (item.Text) {
    const edjsParser = edjsHTML()

    let html = ""

    // FIXME : pourquoi le text n'est pas toujours du text riche
    try {
      html = edjsParser.parse(JSON.parse(item.Text)).join("")
    } catch {
      html = item.Text
    }

    htmlFormatted =
      html.length > 200 ? html.substring(0, 200 - 1) + "&hellip;" : html
  }

  return (
    <>
      <Text>{item.Image?.name}</Text>
      {data && <Text>{buildHtmlFromString(htmlFormatted)}</Text>}
    </>
  )
}

export function SampleNextArrow(props: { className?: any; onClick?: any }) {
  const { className, onClick } = props
  return <div className={className} onClick={onClick} />
}

export function SamplePrevArrow(props: { className?: any; onClick?: any }) {
  const { className, onClick } = props
  return <div className={className} onClick={onClick} />
}
